import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../layout'
import PostListing from '../components/PostListing'
import config from '../../data/SiteConfig'

const Tags = ({ pageContext, data }) => {
  const { tag } = pageContext
  const postEdges = data.allMarkdownRemark.edges
  return (
    <Layout
      title={`Posts tagged as "${tag}" – ${config.siteTitle}`}
      seoDescription={`This page has all the content tagged as "${tag}"`}
    >
      <div className="container">
        <h1>
          Posts tagged as &nbsp;
          <u>
            <strong>{tag}</strong>
          </u>
        </h1>
        <PostListing postEdges={postEdges} />
      </div>
    </Layout>
  )
}

export default Tags

export const pageQuery = graphql`
  query TagPage($tag: String) {
    allMarkdownRemark(
      limit: 1000
      sort: { fields: { date: DESC } }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
            date
          }
          excerpt
          timeToRead
          frontmatter {
            title
            tags
            categories
            thumbnail {
              childImageSharp {
                gatsbyImageData(width: 200, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
              }
            }
            date
            template
          }
        }
      }
    }
  }
`
