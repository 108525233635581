import React, { Suspense, memo, useContext } from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { subMonths, isBefore } from 'date-fns'
import { GatsbyLink } from './Button'
import { formatDate } from '../utils/global'
import clickSound from '../../static/sounds/click1.mp3'
import ThemeContext from '../context/ThemeContext'

const PostTags = React.lazy(() => import('./PostTags'))

const PostListing = ({ postEdges, simple }) => {
  const getPostList = () =>
    postEdges.map((postEdge) => ({
      path: postEdge.node.fields.slug,
      tags: postEdge.node.frontmatter.tags,
      description: postEdge.node.frontmatter.description,
      thumbnail: postEdge.node.frontmatter.thumbnail,
      title: postEdge.node.frontmatter.title,
      date: postEdge.node.fields.date,
      excerpt: postEdge.node.excerpt,
      timeToRead: postEdge.node.timeToRead,
      categories: postEdge.node.frontmatter.categories,
    }))
  const theme = useContext(ThemeContext)

  return (
    <section className={`posts ${simple ? 'simple' : ''}`}>
      {getPostList().map((post) => {
        const thumbnail = getImage(post?.thumbnail)
        const { categories = [] } = post
        const popular = categories?.includes('popular')
        const date = formatDate(post.date)
        const newest = isBefore(subMonths(new Date(), 1), new Date(post.date))
        const timeToRead = post?.timeToRead ? `${post?.timeToRead} min read` : ''
        return (
          <GatsbyLink title={post.title} to={`/${post.path}`} key={post.title} sound={clickSound}>
            <div className="each">
              <div
                className="flex flex-direction-column text-center padding-1"
                style={{
                  paddingTop: '10px',
                }}
              >
                <div>
                  {thumbnail ? (
                    <GatsbyImage
                      style={{
                        filter: theme.dark ? 'grayscale(60%)' : '',
                      }}
                      image={thumbnail}
                      alt={post.title}
                    />
                  ) : (
                    <div />
                  )}
                </div>
                {newest && (
                  <div className="alert showMobile margin-0">
                    <div className="new">New!</div>
                  </div>
                )}
                {popular && !newest && (
                  <div className="alert showMobile margin-0">
                    <div className="popular">Popular</div>
                  </div>
                )}
              </div>

              <div className="each-list-item">
                <h2>{post.title}</h2>
                <i className="datetime">{post.description}</i>
                <div className="datetime">{date}</div>
                {post?.timeToRead && <div className="datetime">{timeToRead}</div>}
                <Suspense fallback={<span>Loading...</span>}>
                  <PostTags tags={post.tags} />
                </Suspense>
                <p className="excerpt hideMobile">{post.excerpt}</p>
              </div>
              {newest && (
                <div className="alert hideMobile">
                  <div className="new">New!</div>
                </div>
              )}
              {popular && !newest && (
                <div className="alert hideMobile">
                  <div className="popular">Popular</div>
                </div>
              )}
            </div>
          </GatsbyLink>
        )
      })}
    </section>
  )
}

export default memo(
  PostListing,
  (prevProps, nextProps) => prevProps.postEdges.length === nextProps.postEdges.length,
)
